.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 48px;
  background-color: #fff;
  margin-bottom: 12px;
}
.trigger {
  padding: 0 12px;
  font-size: 18px;
  line-height: 48px;
  cursor: pointer;
  transition: 'color 0.3s';
}
.root :global(.ant-breadcrumb) {
  height: 48px;
  line-height: 43px;
}
.userIcon {
  padding-right: 40px;
  text-align: 'right';
}
.userInfo {
  line-height: 48px;
  padding: 0 12px;
  text-align: right;
}
