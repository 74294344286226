.ant-tree li {
  padding: 2px 0;
}
.ant-tree-title,
.ant-select-selection__choice__content,
.ant-select-tree-title {
  display: inline-block;
  max-width: 200px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-tree > li > .ant-tree-checkbox-disabled {
  display: none;
}
