.root {
  min-width: 1600px;
  height: 100vh;
  min-height: 900px;
}
.main {
  height: 100vh;
  transition: all 0.2s;
}
.content {
  display: flex;
}
.header {
  padding: 0;
  margin-bottom: 12px;
  height: auto;
  background: #f0f2f5;
}
