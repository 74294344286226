.root {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 2;
}
.logoWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 16px;
  height: 32px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}
.logo {
  width: 40px;
  height: 30px;
  background-image: url('../../../assets/image/logo.png');
  zoom: 0.8;
}
