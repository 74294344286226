/**
 * @File: 全局共有的 CSS
 */
#root {
  width: 100%;
  height: 100%;
}
.page {
  flex: auto;
  padding: 12px;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 104px);
  background-color: #fff;
}
.primaryTextBtn {
  color: #108ee9;
  cursor: pointer;
}
.dangerTextBtn {
  color: #f04134;
  cursor: pointer;
}
