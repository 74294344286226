.SliderMenu_root__1Bf9a {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 2;
}
.SliderMenu_logoWrapper__1VBEl {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px;
  height: 32px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}
.SliderMenu_logo__109PZ {
  width: 40px;
  height: 30px;
  background-image: url(/static/media/logo.8dcd3616.png);
  zoom: 0.8;
}

.GlobalHeader_root__skcic {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
  height: 48px;
  background-color: #fff;
  margin-bottom: 12px;
}
.GlobalHeader_trigger__27IV8 {
  padding: 0 12px;
  font-size: 18px;
  line-height: 48px;
  cursor: pointer;
  -webkit-transition: 'color 0.3s';
  transition: 'color 0.3s';
}
.GlobalHeader_root__skcic .ant-breadcrumb {
  height: 48px;
  line-height: 43px;
}
.GlobalHeader_userIcon__2rNhp {
  padding-right: 40px;
  text-align: 'right';
}
.GlobalHeader_userInfo__2FlhR {
  line-height: 48px;
  padding: 0 12px;
  text-align: right;
}

.Layouts_root__mCon0 {
  min-width: 1600px;
  height: 100vh;
  min-height: 900px;
}
.Layouts_main__RW7no {
  height: 100vh;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.Layouts_content__17W0F {
  display: -webkit-flex;
  display: flex;
}
.Layouts_header__1pYEH {
  padding: 0;
  margin-bottom: 12px;
  height: auto;
  background: #f0f2f5;
}

/**
 * @File: 全局共有的 CSS
 */
#root {
  width: 100%;
  height: 100%;
}
.page {
  -webkit-flex: auto;
          flex: auto;
  padding: 12px;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 104px);
  background-color: #fff;
}
.primaryTextBtn {
  color: #108ee9;
  cursor: pointer;
}
.dangerTextBtn {
  color: #f04134;
  cursor: pointer;
}

/**
 * @File: 面向属性的 CSS
 */

/*
 * 盒模型
 */
.flex1 {
  -webkit-flex: 1 1;
          flex: 1 1;
}

/*
 * display
 */
.dpnone {
  display: none;
}

/*
 * 字体大小
 */
.fs16 {
  font-size: 16px;
}

.tar {
  text-align: right;
}

/*
 * 盒模型
 */
.pd12 {
  padding: 12px;
}
.pd16 {
  padding: 16px;
}

.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 20px;
}

.mr8 {
  margin-right: 8px;
}
.mr12 {
  margin-right: 12px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}

.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb32 {
  margin-bottom: 32px;
}

.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml16 {
  margin-left: 16px;
}
.ml20 {
  margin-left: 20px;
}

.pt8 {
  padding-top: 8px;
}
.pt12 {
  padding-top: 12px;
}
.pt16 {
  padding-top: 16px;
}
.pt20 {
  padding-top: 20px;
}

.pr8 {
  padding-right: 8px;
}
.pr12 {
  padding-right: 12px;
}
.pr16 {
  padding-right: 16px;
}
.pr20 {
  padding-right: 20px;
}
.pr24 {
  padding-right: 24px;
}

.pb8 {
  padding-bottom: 8px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb20 {
  padding-bottom: 20px;
}

.pl8 {
  padding-left: 8px;
}
.pl12 {
  padding-left: 12px;
}
.pl16 {
  padding-left: 16px;
}
.pl20 {
  padding-left: 20px;
}
.pl24 {
  padding-left: 24px;
}

.widthPct100 {
  width: 100%;
}
.widthPct33 {
  width: 33.3%;
}
.widthPct50 {
  width: 50%;
}

/*
  * 字色
  */
.fcf04 {
  color: #f04134;
}
.fc108 {
  color: #108ee9;
}

/*
  * 背景色
  */
.bgfff {
  background-color: #fff;
}

/*
 * 字重
 */
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.width100 {
  width: '100%';
}
/*
 * 对齐
 */
.tacenter {
  text-align: center;
}

/**
 * @File: 覆盖样式的 CSS
 */
a:link {
  text-decoration: none;
}

.ant-card-head-title {
  padding: 6px 0;
}
.ant-tree li {
  padding: 2px 0;
}
.ant-tree-title,
.ant-select-selection__choice__content,
.ant-select-tree-title {
  display: inline-block;
  max-width: 200px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-tree > li > .ant-tree-checkbox-disabled {
  display: none;
}

