/**
 * @File: 面向属性的 CSS
 */

/*
 * 盒模型
 */
.flex1 {
  flex: 1;
}

/*
 * display
 */
.dpnone {
  display: none;
}

/*
 * 字体大小
 */
.fs16 {
  font-size: 16px;
}

.tar {
  text-align: right;
}

/*
 * 盒模型
 */
.pd12 {
  padding: 12px;
}
.pd16 {
  padding: 16px;
}

.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 20px;
}

.mr8 {
  margin-right: 8px;
}
.mr12 {
  margin-right: 12px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}

.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb32 {
  margin-bottom: 32px;
}

.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml16 {
  margin-left: 16px;
}
.ml20 {
  margin-left: 20px;
}

.pt8 {
  padding-top: 8px;
}
.pt12 {
  padding-top: 12px;
}
.pt16 {
  padding-top: 16px;
}
.pt20 {
  padding-top: 20px;
}

.pr8 {
  padding-right: 8px;
}
.pr12 {
  padding-right: 12px;
}
.pr16 {
  padding-right: 16px;
}
.pr20 {
  padding-right: 20px;
}
.pr24 {
  padding-right: 24px;
}

.pb8 {
  padding-bottom: 8px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb20 {
  padding-bottom: 20px;
}

.pl8 {
  padding-left: 8px;
}
.pl12 {
  padding-left: 12px;
}
.pl16 {
  padding-left: 16px;
}
.pl20 {
  padding-left: 20px;
}
.pl24 {
  padding-left: 24px;
}

.widthPct100 {
  width: 100%;
}
.widthPct33 {
  width: 33.3%;
}
.widthPct50 {
  width: 50%;
}

/*
  * 字色
  */
.fcf04 {
  color: #f04134;
}
.fc108 {
  color: #108ee9;
}

/*
  * 背景色
  */
.bgfff {
  background-color: #fff;
}

/*
 * 字重
 */
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.width100 {
  width: '100%';
}
/*
 * 对齐
 */
.tacenter {
  text-align: center;
}
